import axios from 'axios';
import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    config => {
        const userStore = useUserStore();
        const token = userStore.user.token;

        if (token) {
            if(config.headers){
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response; // Falls du auch den success-Handler benötigst
    },
    async error => {
        await handleError(error);
        return Promise.reject(error);
    }
);

export async function handleError(error: any) {
    console.log(error)
    if (error.response) {
        switch (error.response.status) {
            case 401:
            await auth.logOut();
                break;
            case 403:
                await auth.logOut();
                break;
        }
    }
}

export default axiosInstance;
