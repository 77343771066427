import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import axios from "axios"
import auth from "@/auth"
// @ts-ignore
import {UserModel} from "@/models/user.model";
import {useSupplierStore} from "@/stores/SupplierStore";
import axiosInstance from "@/plugins/axios";

export const useUserStore = defineStore('UserStore', {

    state: () => {

        const user: UserModel = {token: null}
        const userData: UserModel = {token: null}

        return {
            user,
            userData
        };
    },
    actions: {

        async updateUserSupplierMapping(user_id: any, supplierArray: any) {

            const applicationStore = useApplicationStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axiosInstance.post(applicationStore.getApiUrl + 'userSupplierMapping', {
                    user_id: user_id, supplier: supplierArray,
                });

                console.log("updated userSupplierMapping")

            } catch (error: any) {
                throw error;
            }
        },

        async authenticateUser(email: string, password: string) {

            const applicationStore = useApplicationStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axiosInstance.post(applicationStore.getApiUrl + 'auth/login', {
                    email: email, password: password,
                });

                console.log("userdata", data);
                this.user = data.data;

            } catch (error: any) {
                throw error;
            }
        },

        async getUsers() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axiosInstance.get(applicationStore.getApiUrl + 'user', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched supplier data..");
                this.userData = data.data;

            } catch (error: any) {
                throw error;
            }
        },

        async updateSingleUser(idToUpdate: any, elementToUpdate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.put(applicationStore.getApiUrl + 'user/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },

        async createSingleUser(elementToCreate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.post(applicationStore.getApiUrl + 'user', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },
        async deleteSingleUser(idToDelete: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.delete(applicationStore.getApiUrl + 'user/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },
    },
    getters: {

        getSupplierByUser() {

            const supplierStore = useSupplierStore();
            const userStore: any = useUserStore();

            const supplier = [];

            if (userStore.user.user_supplier_mappings) {

                for (let i = 0; i < userStore.user.user_supplier_mappings.length; i++) {
                    supplier[i] = userStore.user.user_supplier_mappings[i].supplier
                }
            }

            return supplier;

        }

    },
    persist: true,
});