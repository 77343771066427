import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import {useUserStore} from "@/stores/UserStore";
import axiosInstance from "@/plugins/axios";

export const useSupplierDataStore = defineStore('SupplierDataStore', {

    state: () => {

        const supplierData: any[] = [];
        const availableYears : any[] = [];

        return {
            supplierData,
            availableYears
        };
    },
    actions: {

        async getSupplierDataBySupplierIdAndYear(supplierId: any, year: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axiosInstance.get(applicationStore.getApiUrl + 'supplierData/supplier/' + supplierId + "/year/" + year, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched supplier data..");
                this.supplierData = data.data;

            } catch (error: any) {
                throw error;
            }
        },

        async getAvailableYears() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axiosInstance.get(applicationStore.getApiUrl + 'supplierData/year', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched supplierData Year data..");
                this.availableYears = data.data.reverse();

            } catch (error: any) {
                throw error;
            }
        },

        async updateSingleSupplierData(idToUpdate: any, elementToUpdate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.put(applicationStore.getApiUrl + 'supplierData/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },

        async createSingleSupplierData(elementToCreate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.post(applicationStore.getApiUrl + 'supplierData', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },
        async deleteSingleSupplierData(idToDelete: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axiosInstance.delete(applicationStore.getApiUrl + 'supplierData/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },
    },
    getters: {},
    persist: true,
});